@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBottomWrapperText from global;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.finePrint {
  composes: marketplaceModalHelperLink from global;
  padding: 3px 0 5px 0;
  text-align: left;
  color: var(--Black-Shade-Para_Text, #949494);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.privacyLink,
.termsLink {
  composes: marketplaceModalHelperLink from global;
  color: var(--Web-Primary, #3d70a1);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;

  &:hover {
    color: var(--marketplaceColorDark);
    text-decoration: underline;
    cursor: pointer;
  }
}

@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
  & label {
    display: none;
  }
  & input {
    color: #7b7a7a;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 49px;
    @media (max-width: 767px) {
      height: 38px;
    }
    &::placeholder {
      color: #7b7a7a;
      font-family: Nunito;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &:focus::-webkit-input-placeholder {
      opacity: 0;
    }
  }
}

.defaultUserFields {
}

.name {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 24px;
  }
  @media (--viewportMedium) {
    margin-bottom: 24px;
  }
}

.row,
.customFields {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(50% - 9px);
  @media (max-width: 767px) {
    width: 100%;
  }
}

.lastNameRoot {
  width: calc(50% - 9px);
  @media (max-width: 767px) {
    width: 100%;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 20px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.alreadyAccount {
  text-align: center;
  margin-top: 20px;
  & span,
  & a {
    color: var(--Black-Shade-Para_Text, #949494);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  & a {
    color: var(--Web-Primary, #3d70a1);
    text-decoration: underline;
  }
}
.orText {
  color: var(--Black-Shade-Inactive, #dbdbdb);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
  padding: 0;
  margin: 10px 0 15px;
}

.bottomWrapper {
  margin-top: 40px;
  & > div {
    padding-bottom: 10px;
  }
  & label {
    display: block;
    display: flex;
    align-items: center;
  }
  & > button {
    border-radius: 45px;
    background: var(--Web-Primary, #3d70a1);
    height: 59px;
    color: var(--Black-Shade-White, #fff);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 767px) {
      height: 44px;
      min-height: 44px;
    }
  }
}
.socialButton {
  display: flex;
  justify-content: space-between;
  @media (max-width: 580px) {
    flex-direction: column;
    gap: 16px;
  }
  & button {
    width: calc(50% - 10px);
    border-radius: 10px;
    border: 1px solid var(--Black-Shade-Inactive, #dbdbdb);
    background: var(--Black-Shade-BG, #f8f8f8);
    color: var(--Black-Shade-Para_Text, #949494);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-height: 49px;
    @media (max-width: 580px) {
      width: 100%;
      min-height: 44px;
    }
    & img {
      margin-right: 4px;
    }
  }
}
.radioWrapper {
  display: flex;
  gap: 14px;
  margin-bottom: 16px;
  @media (max-width: 767px) {
    gap: 20px;
  }
  & label {
    display: block;
    & span {
      color: var(--Black-Shade-Para_Text, #949494);
      font-family: Nunito;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.subtitle {
  color: var(--Black-Shade-Para_Text, #949494);

  /* Web/Paragrap-18 */
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
  margin: 0 0 13px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}

/* .bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
} */

.phoneWrapper {
  margin-bottom: 24px;
  margin-top: 24px;
  & input {
    border-radius: 10px;
  }
}

.organizationName {
  margin-bottom: 1.5rem;
}

.subtitle {
  color: var(--Black-Shade-Para_Text, #949494);

  /* Web/Paragrap-18 */
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
  margin: 0 0 13px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
& :global(.react-tel-input) {
  background-color: #f3fcff;
  height: 48px !important;
  outline: none;
  transition: all ease-in-out 100ms;
  /* border: 1px solid #538ABE !important; */
}
& :global(.form-control) {
  width: 100% !important;
  background-color: #f3fcff !important;
  border-radius: 10px;
  border: 1px solid #538abe !important;
  height: 48px !important;
}
& :global(.selected-flag) {
  border: 1px solid #538abe !important;
  border-right: none !important;
}
& :global(.flag-dropdown) {
  background-color: #f3fcff !important;
  border: none !important;
}
.radioWrapper {
  display: flex;
  gap: 14px;
  margin-bottom: 16px;
  @media (max-width: 767px) {
    gap: 20px;
  }
  & label {
    display: flex;
    align-items: center;
    & span {
      color: var(--Black-Shade-Para_Text, #949494);
      font-family: Nunito;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

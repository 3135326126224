@import '../../../styles/customMediaQueries.css';

.root {
  /* composes: marketplaceModalFormRootStyles from global; */

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
  & label {
    display: none;
  }
  & input {
    color: #7b7a7a;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 49px;
    @media (max-width: 767px) {
      height: 38px;
    }
    &::placeholder {
      color: #7b7a7a;
      font-family: Nunito;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &:focus::-webkit-input-placeholder {
      opacity: 0;
    }
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 24px;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  text-align: right;
  margin-top: 24px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}
.loginButton {
  border-radius: 45px;
  background: var(--Web-Primary, #3d70a1);
  color: var(--Black-Shade-White, #fff);
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 59px;
  @media (max-width: 767px) {
    height: 44px;
    min-height: 44px;
  }
}
.recoveryLink {
  composes: marketplaceModalHelperLink from global;
  margin-bottom: 24px;
  display: block;
  color: var(--Web-Primary, #3d70a1);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}
.alreadyAccount {
  text-align: center;
  margin-top: 20px;
  & span,
  & a {
    color: var(--Black-Shade-Para_Text, #949494);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  & a {
    color: var(--Web-Primary, #3d70a1);
    text-decoration: underline;
  }
}
.orText {
  color: var(--Black-Shade-Inactive, #dbdbdb);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
  padding: 0;
  margin: 10px 0 15px;
}

.socialButton {
  display: flex;
  justify-content: space-between;
  @media (max-width: 580px) {
    flex-direction: column;
    gap: 16px;
  }
  & button {
    width: calc(50% - 10px);
    border-radius: 10px;
    border: 1px solid var(--Black-Shade-Inactive, #dbdbdb);
    background: var(--Black-Shade-BG, #f8f8f8);
    color: var(--Black-Shade-Para_Text, #949494);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-height: 49px;
    @media (max-width: 580px) {
      width: 100%;
      min-height: 44px;
    }
    & img {
      margin-right: 4px;
    }
  }
}
